.staff-container[data-v-b1506091] {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.staff-header[data-v-b1506091] {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.5rem;
  color: var(--color-text-primary);
  padding: 1rem 1rem 0;
}
.staff-table[data-v-b1506091] {
  padding: 1rem;
}
.staff-week-days[data-v-b1506091] {
  position: relative;
  display: flex;
  gap: 0.4rem;
}
.staff-week-day-half-day[data-v-b1506091] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-quaternary);
}
.staff-week-day-half-day.-morning[data-v-b1506091] {
  background: linear-gradient(to right, var(--color-text-submit-focus) 50%, var(--content-bg--color-dark) 50%);
}
.staff-week-day-half-day.-afternoon[data-v-b1506091] {
  background: linear-gradient(to left, var(--color-text-submit-focus) 50%, var(--content-bg--color-dark) 50%);
}
.staff-week-day-half-day.-morning.-afternoon[data-v-b1506091] {
  background: var(--color-text-submit-focus);
}
.staff-week-day-half-day[data-v-b1506091]:not(.-morning):not(.-afternoon) {
  background: var(--content-bg--color-dark);
}
.staff-name-link[data-v-b1506091] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-primary);
  text-decoration: none;
}
.staff-name-link[data-v-b1506091]:hover {
  text-decoration: underline !important;
  background-color: transparent !important;
}