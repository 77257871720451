.calendar-app[data-v-89368927] {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.calendar[data-v-89368927] {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 0.5rem;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
}
.calendar-loading-data[data-v-89368927] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.loading-icon[data-v-89368927] {
  color: var(--color-text-primary);
  font-size: 2rem;
}