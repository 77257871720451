.notification-replacement[data-v-326c3569] {
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  height: 4rem;
}
.notification-replacement-car-name[data-v-326c3569] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  width: 15rem;
}
.notification-replacement-delimiter[data-v-326c3569] {
  width: 0.1rem;
  height: 100%;
  background-color: var(--color-border-primary);
}
.notification-borrows[data-v-326c3569] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.notification-replacement-block[data-v-326c3569] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  padding: 0.1rem 0.4rem;
  border-radius: var(--radius);
}
.notification-replacement-block.-in-progress[data-v-326c3569] {
  background-color: var(--car-state-coming-shaded);
}
.notification-replacement-block.-in-progress[data-v-326c3569]:hover {
  background-color: var(--car-state-coming);
}
.notification-replacement-block.-coming[data-v-326c3569] {
  border: 0.4px solid var(--car-state-todo-border);
}
.notification-replacement-car-name[data-v-326c3569] {
  width: 14rem;
}
.notification-replacement-car-name.-coming[data-v-326c3569] {
  margin-left: 1.4rem;
}
.notification-replacement-alert[data-v-326c3569] {
  color: var(--color-text-warning);
}