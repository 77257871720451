.calendar-week-chart[data-v-5d82b027] {
  position: relative;
  width: 100%;
  height: 7.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 1rem;
}
.calendar-done-work[data-v-5d82b027] {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.5rem;
  color: var(--color-text-primary);
  position: absolute;
  text-align: center;
  width: 5rem;
  opacity: 1;
  transform: translateY(calc(50% + 0.8rem)) translateX(-0.5rem);
  transition: opacity var(--p-transition-duration) ease-in-out;
}
.calendar-chart:hover + .calendar-done-work[data-v-5d82b027] {
  opacity: 0;
}
.calendar-chart[data-v-5d82b027] {
  width: 6.5rem;
  height: 6.5rem;
}
canvas[data-v-5d82b027] {
  width: 100% !important;
  height: 100% !important;
  max-width: 30rem;
}
.calendar-done-work-explanation[data-v-5d82b027] {
  width: 1rem;
  height: 1rem;
  transform: translateX(1rem);
  color: var(--color-text-secondary);
}