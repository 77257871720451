.staff-form-block[data-v-d94493f5] {
  display: flex;
  gap: 1rem;
  margin-top: 0.4rem;
}
.staff-form-block.-margin-top[data-v-d94493f5] {
  margin-top: 1rem;
}
.staff-form-validate[data-v-d94493f5] {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
.staff-form-button[data-v-d94493f5] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-quaternary);
  background-color: var(--color-button-bg-submit-full);
  padding: 0.4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.staff-form-button[data-v-d94493f5]:hover {
  background-color: var(--color-button-bg-submit-full-hover);
}