.car-options[data-v-55d7056f] {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  flex-direction: column;
  width: 100%;
  margin-top: 0.4rem;
}
.car-options-delimiter[data-v-55d7056f] {
  width: 90%;
  height: 1px;
  background-color: var(--color-border-secondary);
}
.car-option-button[data-v-55d7056f] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  display: flex;
  align-items: center;
  height: 1.6rem;
  width: 100%;
}
.car-option-icon[data-v-55d7056f] {
  color: var(--color-icon);
}
.car-option-text[data-v-55d7056f] {
  opacity: 0.8;
}
.car-option-button:hover .car-option-text[data-v-55d7056f],
.car-option-button:hover .car-option-icon[data-v-55d7056f] {
  opacity: 1;
  color: var(--color-icon-focus);
}