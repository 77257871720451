.contract-form-block[data-v-dda9fb0f] {
  display: flex;
  gap: 1rem;
  margin-top: 0.4rem;
}
.contract-form-block.-margin-top[data-v-dda9fb0f] {
  margin-top: 1rem;
}
.contract-form-block.-column[data-v-dda9fb0f] {
  flex-direction: column;
}
.contract-form-subtitle[data-v-dda9fb0f] {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.5rem;
  color: var(--color-text-primary);
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-top: 0.4rem;
}
.contract-form-validate[data-v-dda9fb0f] {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
.contract-form-button[data-v-dda9fb0f] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-quaternary);
  background-color: var(--color-button-bg-submit-full);
  padding: 0.4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contract-form-button[data-v-dda9fb0f]:hover {
  background-color: var(--color-button-bg-submit-full-hover);
}
.contract-form-checkbox[data-v-dda9fb0f] {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  width: fit-content;
  padding-inline: 0;
  padding-block: 0;
}
.contract-form-checkbox[data-v-dda9fb0f]:hover {
  background-color: var(--content-bg--color-lighter);
}