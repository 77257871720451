.staff-timeline-marker-date[data-v-feed87fd] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  align-self: baseline;
  border-width: 2px;
  border-style: solid;
  border-color: var(--color-text-secondary);
  border-radius: 999px;
  width: 18px;
  height: 18px;
}
.staff-timeline-marker[data-v-feed87fd] {
  background-color: var(--color-button-bg-submit);
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}
.staff-timeline-marker.-end[data-v-feed87fd] {
  background-color: var(--color-text-success);
}
.staff-timeline-icon[data-v-feed87fd] {
  color: var(--color-text-quaternary);
}
.staff-timeline-contract[data-v-feed87fd],
.staff-timeline-date[data-v-feed87fd] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  display: flex;
  align-items: center;
  gap: 0.4rem;
  transform: translateY(-0.2rem);
}
.staff-timeline-contract.-flex-end[data-v-feed87fd],
.staff-timeline-date.-flex-end[data-v-feed87fd] {
  justify-content: flex-end;
}
.staff-timeline-edit[data-v-feed87fd] {
  padding: 0.4rem;
  color: var(--color-text-secondary);
}
.staff-timeline-edit[data-v-feed87fd]:hover {
  color: var(--color-text-primary);
}