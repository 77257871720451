.car-details[data-v-31225f4d] {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 20rem;
}
.car-details-title[data-v-31225f4d] {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.5rem;
  color: var(--color-text-primary);
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-top: 0.4rem;
}
.car-details-title[data-v-31225f4d]:first-of-type {
  margin-top: 0;
}
.car-details-block-text[data-v-31225f4d] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  display: flex;
  gap: 0.4rem;
  align-items: flex-start;
  justify-content: flex-start;
}
.car-details-block-text.-warning[data-v-31225f4d] {
  color: var(--color-text-warning);
}
.car-details-block-text.-work[data-v-31225f4d] {
  color: var(--color-text-secondary);
}
.car-details-block-text.-work > span[data-v-31225f4d] {
  color: var(--color-text-primary);
}
.car-details-icon-text[data-v-31225f4d] {
  margin-top: 0.3rem;
  color: var(--color-text-quaternary-secondary);
}
.insurance__box.-calendar[data-v-31225f4d] {
  box-shadow: var(--p-select-overlay-shadow);
}
.form__textarea.-calendar-car[data-v-31225f4d] {
  border: 0.1px solid var(--color-text-primary);
}
.car-details-icon-text-warning[data-v-31225f4d] {
  color: var(--color-bg-warning);
  margin-top: 0.15rem;
}