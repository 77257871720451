.insurance-create-container[data-v-50265d45] {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 0.4rem;
}
.insurance-create[data-v-50265d45] {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.insurance-create-button[data-v-50265d45] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-quaternary);
  background-color: var(--color-button-bg-submit-full);
  padding: 0.4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.insurance-create-button[data-v-50265d45]:hover {
  background-color: var(--color-button-bg-submit-full-hover);
}
.insurance-create-error[data-v-50265d45] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-warning);
}