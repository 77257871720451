
.fade-slide-enter-active[data-v-db21571b],
.fade-slide-leave-active[data-v-db21571b] {
  transition: opacity var(--p-transition-duration) ease, transform var(--p-transition-duration) ease;
}
.fade-slide-enter-from[data-v-db21571b],
.fade-slide-leave-to[data-v-db21571b] {
  opacity: 0;
  transform: scale(0.9);
}
