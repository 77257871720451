.replacement-car-button[data-v-5c1a0c1b] {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 0;
  padding-block: 0;
}
.replacement-car-button[data-v-5c1a0c1b]:hover {
  background-color: transparent;
  transform: scale(1.1);
}
.replacement-car-icon[data-v-5c1a0c1b] {
  width: 1.2rem;
  height: 1.2rem;
  color: var(--color-text-submit);
}
.replacement-car-icon.-finished[data-v-5c1a0c1b] {
  color: var(--color-text-success);
}
.replacement-car-icon.-affected[data-v-5c1a0c1b] {
  color: var(--color-text-feedback);
}