.replacement-header[data-v-0c392b5d] {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.5rem;
  color: var(--color-text-primary);
}
.replacement-header span[data-v-0c392b5d] {
  color: var(--color-text-secondary);
}
.replacement-container[data-v-0c392b5d] {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 0.4rem;
}
.replacement-car-selecter[data-v-0c392b5d] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.replacement-car-insurance-groups[data-v-0c392b5d] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}
.replacement-car-borrow-dates[data-v-0c392b5d] {
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  align-items: center;
  justify-content: center;
  color: var(--color-text-warning);
}
.replacement-car-borrow-icon[data-v-0c392b5d] {
  color: var(--color-text-warning);
  fill: var(--color-text-warning);
}
.replacement-car-header[data-v-0c392b5d] {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.5rem;
  color: var(--color-text-primary);
  display: flex;
  width: calc(100% - 1.5rem);
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 0.4px solid var(--color-border-secondary);
  margin-left: 0.75rem;
}
.replacement-car-valid[data-v-0c392b5d] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.replacement-car-valid-icon[data-v-0c392b5d] {
  color: var(--color-text-success-light);
}
.replacement-car-invalid-icon[data-v-0c392b5d] {
  color: var(--color-text-warning);
}
.replacement-car-empty[data-v-0c392b5d] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
}
.replacement-car-dates[data-v-0c392b5d] {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 30rem;
}
.replacement-car-form-validate[data-v-0c392b5d] {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.4rem;
}
.replacement-car-form-button[data-v-0c392b5d] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-quaternary);
  background-color: var(--color-button-bg-submit-full);
  padding: 0.4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.replacement-car-form-button[data-v-0c392b5d]:hover {
  background-color: var(--color-button-bg-submit-full-hover);
}
.checkbox[data-v-0c392b5d] {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  width: fit-content;
  padding-inline: 0;
  padding-block: 0;
}
.checkbox[data-v-0c392b5d]:hover {
  background-color: var(--content-bg--color-lighter);
}
.checkbox-replacement[data-v-0c392b5d] {
  width: 0.8rem;
  height: 0.8rem;
}
@keyframes fadeSlideIn-0c392b5d {
from {
    opacity: 0;
    transform: translateY(20px);
}
to {
    opacity: 1;
    transform: translateY(0);
}
}
.conflict-replacements[data-v-0c392b5d] {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  animation: fadeSlideIn-0c392b5d 0.5s ease-out;
}
.conflict-message[data-v-0c392b5d] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-warning);
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.conflict-replacement[data-v-0c392b5d] {
  display: flex;
  flex-direction: column;
  border: 0.1px solid var(--color-text-quaternary-secondary);
  padding: 0.4rem;
  border-radius: var(--radius);
}