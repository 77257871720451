.notification-car-line[data-v-d0d54e60] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.notification-car-date[data-v-d0d54e60] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-secondary);
  width: 10rem;
}
.notification-car[data-v-d0d54e60] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 12px);
  height: 1.6rem;
  border: 0.4px solid var(--color-border-tertiary);
  border-radius: var(--radius);
  background-color: var(--content-bg--color-light);
  padding: 0 6px;
}
.notification-car[data-v-d0d54e60]:hover {
  background-color: var(--color-bg-hover);
}
.notification-car-state[data-v-d0d54e60] {
  display: flex;
  width: 1.2rem;
  justify-content: flex-start;
  align-items: center;
}
.notification-car-details[data-v-d0d54e60] {
  display: flex;
  width: calc(100% - 1.2rem);
  gap: 0.4rem;
  padding-block: 0;
  padding-inline: 0;
  height: 1.6rem;
}
.notification-car-details[data-v-d0d54e60]:hover {
  background-color: transparent;
}
.car-followup[data-v-d0d54e60] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 0.4rem;
  min-width: 0.4rem;
  height: 100%;
}
.car-followup.-deleted[data-v-d0d54e60] {
  opacity: 0.4;
}
.car-followup-point[data-v-d0d54e60] {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
}
.car-followup-point.-incomplete[data-v-d0d54e60] {
  background-color: var(--car-followup-incomplete);
}
.car-followup-point.-complete[data-v-d0d54e60] {
  background-color: var(--car-followup-complete);
}