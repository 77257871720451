.input-subtitle[data-v-0147252c] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
}
.input-textarea[data-v-0147252c] {
  color: var(--color-text-primary);
  padding: 0.4rem;
  border-radius: var(--radius);
  margin-top: 1rem;
  width: 100%;
  background-color: var(--content-bg--color-lighter);
}
.car-incharge-form-validate[data-v-0147252c] {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.4rem;
}
.car-incharge-form-button[data-v-0147252c] {
  padding: 0.4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}