.notifications-empty[data-v-0cb0ef8e] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-secondary);
  text-align: left;
  width: 30rem;
}
.notifications[data-v-0cb0ef8e] {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.notification-read-button[data-v-0cb0ef8e] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-quaternary);
  background-color: var(--color-button-bg-submit);
  padding: 0.4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  margin-top: 2rem;
}