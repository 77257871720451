.fade-enter-active[data-v-19c95785], .fade-leave-active[data-v-19c95785] {
  transition: opacity 0.3s;
}
.fade-enter[data-v-19c95785], .fade-leave-to[data-v-19c95785] {
  opacity: 0;
}
.calendar-week-day-car[data-v-19c95785] {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  border: 0.5px solid var(--color-border-tertiary);
  border-radius: var(--radius);
  background-color: var(--content-bg--color-light);
  padding-inline: 6px;
  height: 1.6rem;
  transition: height var(--p-transition-duration) ease-in-out;
  overflow: hidden;
}
.calendar-week-day-car.-draggable[data-v-19c95785] {
  position: absolute;
  width: calc((100% - 25rem) / 5);
  z-index: 1000000;
}
.calendar-week-day-car.-mechanic[data-v-19c95785] {
  background-color: var(--color-bg-mechanic);
}
.calendar-week-day-car.-highlighted[data-v-19c95785] {
  border-color: var(--color-due-date);
}
.calendar-week-day-car.-car.-expanded[data-v-19c95785] {
  height: 12.8rem;
}
.calendar-week-day-car.-work.-expanded[data-v-19c95785] {
  height: 14.4rem;
}
.calendar-week-day-car.-end-valid-support-date-over[data-v-19c95785] {
  border-color: var(--car-followup-incomplete);
}
.calendar-week-day-car.-deleted[data-v-19c95785] {
  border-color: var(--color-border-warning);
}
.calendar-week-day-car.-deleted.-expanded[data-v-19c95785] {
  height: 6.8rem;
}
.calendar-week-day-car.-with-note[data-v-19c95785] {
  height: 3.6rem;
}
.calendar-week-day-car.-with-note.-work.-expanded[data-v-19c95785] {
  height: 16rem;
}
.calendar-week-day-car.-with-note.-car.-expanded[data-v-19c95785] {
  height: 14.2rem;
}
.calendar-week-day-car.-with-note.-deleted.-expanded[data-v-19c95785] {
  height: 7.8rem;
}
.calendar-week-day-car.-folder[data-v-19c95785] {
  height: fit-content;
}
.calendar-week-day-car.-folder > .calendar-week-day-car-container[data-v-19c95785] {
  padding-right: 0.8rem;
}
.calendar-week-day-car.-folder > .calendar-week-day-car-container > .car-followup[data-v-19c95785] {
  display: none;
}
.calendar-week-day-car.-hidden[data-v-19c95785] {
  display: none;
}
.calendar-car-missing-points[data-v-19c95785] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  height: fit-content;
  width: 100%;
  color: var(--color-text-warning);
  padding: 0.1rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4rem;
  align-items: flex-start;
}
.missing-points-separator[data-v-19c95785] {
  background: var(--color-text-warning);
  max-height: 0.3rem;
  max-width: 0.3rem;
  min-height: 0.3rem;
  min-width: 0.3rem;
  border-radius: 50%;
  margin: auto 0;
}
.calendar-week-day-car-container[data-v-19c95785] {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 0.4rem;
}
.calendar-week-day-car-name[data-v-19c95785] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.car-name[data-v-19c95785] {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.car-name.-result[data-v-19c95785] {
  background-color: var(--search-highlighted-bg);
  color: var(--search-text-color);
  box-shadow: 0 0 4px 1px var(--search-box-shadow-color);
  height: 1.1rem;
  margin-top: 0.2rem;
  align-items: center;
  display: flex;
}
.calendar-week-day-car-note-container[data-v-19c95785] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-secondary);
  display: flex;
  gap: 0.4rem;
  width: 100%;
  align-items: center;
}
.calendar-week-day-car-note[data-v-19c95785] {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
.calendar-week-day-car > .p-tieredmenu-overlay[data-v-19c95785] {
  display: none;
}
.calendar-week-day-car-slot[data-v-19c95785] {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 1.6rem;
  min-width: 1.6rem;
}
.seriously-damaged-icon[data-v-19c95785] {
  color: var(--color-text-warning);
}
.tow-truck-icon[data-v-19c95785] {
  width: 1.2rem;
  height: 1.2rem;
  color: var(--color-icon);
}
.calendar-week-day-car.-deleted .calendar-week-day-car-name[data-v-19c95785] {
  text-decoration: line-through;
  color: var(--color-text-secondary);
  text-decoration-color: var(--color-text-warning);
}
.calendar-week-day-car.-deleted .calendar-week-day-car-slot[data-v-19c95785] {
  opacity: 0.4;
}
.calendar-week-day-car-name[data-v-19c95785]:hover {
  cursor: pointer;
}
.calendar-week-day-car[data-v-19c95785]:not(.-expanded):hover {
  background-color: var(--color-button-bg-focus);
}
.calendar-week-day-car.-mechanic[data-v-19c95785]:not(.-expanded):hover {
  background-color: var(--color-bg-mechanic-hover);
}