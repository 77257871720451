.calendar-toolbar[data-v-4df0be6a] {
  display: flex;
  padding: 1rem;
  border-bottom: 0.4px solid var(--color-border-primary);
}
.calendar-toolbar-left[data-v-4df0be6a],
.calendar-toolbar-right[data-v-4df0be6a] {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  width: calc(50% - 5rem);
}
.calendar-toolbar-right[data-v-4df0be6a] {
  justify-content: space-between;
  align-items: center;
  width: calc(50% + 5rem);
}
.calendar-buttons[data-v-4df0be6a] {
  display: flex;
  gap: 1rem;
}
.calendar-button[data-v-4df0be6a] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-quaternary);
  padding: 0.5rem;
}
.calendar-car-search[data-v-4df0be6a] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 1.6rem;
  gap: 0.4rem;
}
.calendar-week-day-car-name[data-v-4df0be6a] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.car-name[data-v-4df0be6a] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 15rem;
}
.calendar-week-day-car-slot[data-v-4df0be6a] {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 1.6rem;
  min-width: 1.6rem;
}
.replacement-car-icon[data-v-4df0be6a] {
  width: 1.2rem;
  height: 1.2rem;
  color: var(--color-text-submit);
}
.replacement-car-icon.-finished[data-v-4df0be6a] {
  color: var(--color-text-success);
}
.car-search-empty[data-v-4df0be6a] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
}
.seriously-damaged-icon[data-v-4df0be6a] {
  color: var(--color-text-warning);
}
.tow-truck-icon[data-v-4df0be6a] {
  width: 1.2rem;
  height: 1.2rem;
  color: var(--color-icon);
}
.car-calendar-date[data-v-4df0be6a] {
  width: 4.2rem;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
}
.notification-count[data-v-4df0be6a] {
  position: absolute;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-quaternary);
  background-color: var(--color-text-warning);
  border-radius: 50%;
  transform: translateX(70%) translateY(-60%);
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}