.car-followup[data-v-d85efd9e] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 0.4rem;
  min-width: 0.4rem;
  height: 100%;
}
.car-followup.-deleted[data-v-d85efd9e] {
  opacity: 0.4;
}
.car-followup-point[data-v-d85efd9e] {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
}
.car-followup-point.-incomplete[data-v-d85efd9e] {
  background-color: var(--car-followup-incomplete);
}
.car-followup-point.-complete[data-v-d85efd9e] {
  background-color: var(--car-followup-complete);
}