.staff-toolbar[data-v-db85e1a3] {
  display: flex;
  padding: 1rem;
  border-bottom: 0.4px solid var(--color-border-primary);
  justify-content: flex-start;
}
.staff-button[data-v-db85e1a3] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-quaternary);
  padding: 0.5rem;
}